<template>
  <modal-base>
    <template v-slot:header> </template>
    <template v-slot:body>
      <AydinlatmaIletisim />
    </template>
    <template v-slot:footer> <div></div> </template>
  </modal-base>
</template>

<script>
import ModalBase from "./ModalBase";

export default {
  components: {
    ModalBase,
  },
};
</script>

<style scoped>
p,
p * {
  font-size: 0.8rem;
  line-height: 1.6;
}
</style>


<style scoped>
.isocert {
  padding-left: 30px !important;
}

.altnavmenu {
  cursor: pointer;
}

.altnavmenu a {
  padding: 0 !important;
  margin: 0 !important;
  border-bottom: none !important;
}
nav .altnavmenu span img {
  width: 16px;
}
nav .altnavmenu span {
  transform: rotate(0deg);
  display: flex;
  transition: all 0.3s ease-in;
  margin: 0 0 0 5px;
}
nav .altnavmenu:hover span,
.active_menu span {
  transform: rotate(180deg);
}

header {
  position: fixed;
  z-index: 9;
}

.active_menu_gen {
  visibility: hidden;
  display: flex;
  opacity: 0;
  background: #fff;
  width: 100%;
  z-index: 99;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.3s 0.2s ease-in;
  box-shadow: 0 20px 30px 5px rgba(0, 0, 0, 0.1);
  top: -100px;
}

.active_menu {
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease-in !important;
  top: 0;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  position: relative;
  z-index: 999;
}
nav .logo {
  width: 6vw;
}
nav ul {
  display: flex;
  align-items: flex-end;
  width: 100%;
}
nav ul li > * {
  margin: 0;
}
nav ul li {
  margin: 0;
}
nav ul li.bolder a {
  font-weight: 600;
}

#nav {
  padding: 0;
}
#nav .wide {
  max-width: 1400px;
  padding: 0 2vw;
}

nav a,
nav .spanlink,
nav .altnavmenu {
  color: #4da0df !important;
  font-weight: 400;
  border-bottom: 3px #fff0 solid;
  padding: 30px 10px;
  display: flex;
  align-items: center;
  text-align: center;
}

nav a:hover,
nav .altnavmenu:hover {
  background: #fdfbfa;
  /* border-bottom: 3px #4da0df solid; */
}

.nav_active a {
  border-bottom: 3px #4da0df solid;
}

.altnav nav a {
  color: #55565e !important;
  font-weight: 600;
  transition: all 0.3s ease-in;
  border-bottom: 3px #5550 solid;
  padding: 1.4vh 20px;
  opacity: 0.7;
}

.altnav nav .nav_active a {
  border-bottom: 3px #55565e solid;
}

.altnav nav a:hover {
  /* border-bottom: 3px #fff solid; */
  opacity: 1;
  color: #000 !important;
  background: #fff;
}

.active_menu a {
  border-bottom: 3px rgba(255, 255, 255, 0) solid;
}

/* .active_menu .altnav {animation: entrance 0.6s ease-in-out 0s ;}

@keyframes entrance {
  0% {
    opacity: 0;
    transform: translateY(-50px)
  }
  100% {
    opacity: 1;
    transform: translateY(0px)
  }
} */

.mobile nav {
  padding: 0 !important;
  margin: 0;
}
.mobile .navlinks {
  border-top: 1px solid #e0e9f1;
  border-bottom: 1px solid #e0e9f1;
  margin: 10px 0;
  padding: 10px 0px;
}
.mobile nav a:hover,
.mobile nav .nav_active a {
  background: #fff;
  padding-left: 10px;
}

.mobile nav .hbtn.kur a {
  background: #4da0df;
}

.mobile .mobilekurgroup {
  padding: 5px;
  border: 1px solid #e0e9f1;
  margin: 5px -5px 5px 5px;
  max-width: 100%;
  border-radius: 20px;
}

.mobile nav .kur.vbtn a {
  background: #fdfbfa !important;
  color: #55565e !important;
}

.mobile .navlinks span {
  color: #55565e;
  font-weight: 600;
}
.mobile .altlinks li a {
  color: #55565e !important;
  margin-left: 10px !important ;
}
.mobile .altlinks li {
  margin: 0;
}

.mobile .nav_active a {
  border-bottom: 3px #fff solid !important;
}
.mobile .nav_active a:hover {
  border-bottom: 3px #fff solid !important;
}

.logogroup {
  display: flex;
  align-items: center;
}

header .infologo {
  display: flex;
  justify-content: flex-start;
  margin: 0.5vw 15px 0.5vw 0px;
  border-right: 1px solid #dfdfdf;
}
header .infologo a {
  align-items: center;
  display: flex;
  justify-content: center;
}
header .infologo img {
  width: 100%;
  height: 100%;
  max-width: 60px;
  margin-right: 15px;
}

.stick {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: calc(100vw - 20px);
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(10px);
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.1);
  transition: all 0.7s ease;
}

.stick > div {
  max-width: 1150px;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  transition: all 0.2s ease;
}

.stick .logo,
.stick nav {
  transition: all 0.7s ease;
}

.do {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: calc(100vw - 20px);
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9999;
  backdrop-filter: blur(10px);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.12);
  transition: all 0.7s ease;
  background: rgba(255, 255, 255, 0);
}

.do > div {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 1150px;
  position: relative;
  top: 0;
  margin: 0 auto;
  z-index: 99;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0);
  background: rgba(232, 232, 232, 0);
  transition: all 0.7s ease;
}

.do .logo,
.do nav {
  /* transform: scale(01); */
  transition: all 0.7s ease;
}

.mainnav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  position: relative;
  z-index: 999;
  height: max-content;
}

.topnav {
  display: flex;
  justify-content: flex-end;
}
.topnav.hide {
  width: 20%;
}

.hbtn {
  background: #4da0df;
  display: flex !important;
  align-items: center;
  cursor: pointer;
  margin-left: 0.5vw;
  overflow: hidden;
}

.vbtn {
  background: #fdfbfa;
  display: flex !important;
  align-items: center;
  cursor: pointer;
  margin-left: 0.5vw;
}

.hbtn,
.hbtn:hover,
.vbtn,
.vbtn:hover {
  transition: all 0.3s ease-in;
}

.hbtn a {
  display: flex;
  flex-flow: wrap;
  border: none;
  text-align: center;
  width: 100%;
  color: white;
  align-items: center;
  justify-content: center;
  padding: 14px;
  margin: 0;
  font-weight: 600;
  text-decoration: none !important;
  user-select: none;
}

.hbtn:hover {
  background: #0f66a9;
}
.vbtn a {
  color: #55565e;
}
.vbtn:hover {
  background: #f0e2db;
}

.ulflex {
  display: flex;
  justify-content: flex-end;
}

.bir {
  border-radius: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 1px 1px #4da0df inset;
}
.bir a {
  color: #4da0df;
}
.bir:hover {
  background: #fff;
}

.ayrac {
  padding: 0 0.9vw;
}

.kur {
  border-radius: 10px;
}
.bsv {
  border-radius: 10px;
  border-left: 1px solid #fff;
}
</style>

